import './App.css';
import Button from '@mui/material/Button';
import { useState } from "react";
import { emoji } from "node-emoji";

const first_word = [
  "clippy",
  "content",
  "content-generation",
  "tweets",
  "ucr",
  "listicles",
  "clip",
  "twitter", 
  "twitch",
  "linkedin",
  "youtube",
  "yt",
  "rss",
  "blog"
]

const second_word = [
  "backend",
  "api",
  "metadata",
  "management",
  "bridge",
  "frictionless",
  "status",
  "handler",
  "wrapper",
  "watcher",
  "model"
]

const third_word = [
  "revamp",
  "v2",
  "v3",
  "bot",
  "ec2",
  "lambda",
  "app",
  "dev",
  "prod",
  "workflow",
  "tool",
  "server"
]


function randomEntry(words) {
  return words[Math.floor(Math.random()*words.length)];
}


function App() {
  const [n, setN] = useState(1);

  return (
    <div className="App">
      <header className="App-header">
        <p key={n}>
          <code> {randomEntry(first_word)}-{randomEntry(second_word)}-{randomEntry(third_word)} </code>
        </p>
        <Button
          startIcon={emoji.fire}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setN((n) => n + 1)}>
            Generate new name
        </Button>
      </header>
    </div>
  );
}

export default App;
